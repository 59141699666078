    body {
        background-color: rgb(250,250,250);
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .image {
        width: 50%;
        align-items: center;
    }