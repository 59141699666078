.container {
    margin: 50px 0;
}

.card {
    margin: 0 2% !important;
}

.infected {
    border-bottom: 10px solid rgba(0,0,255,0.5);
}

.recovered {
    border-bottom: 10px solid rgba(0,255,0,0.5);
}

.deaths {
    border-bottom: 10px solid rgba(255,0,0,0.5);
}